
























import { Vue, Component, Watch } from "vue-property-decorator";
import { format, parse, parseISO } from "date-fns";

import AusterButton from "@/components/AusterButton.vue";
import AusterInput from "@/components/AusterInput.vue";
import AusterSelect from "@/components/AusterSelect.vue";
import ChangePasswordModal from "@/components/ChangePasswordModal.vue";

import { validateEntity } from "@/models/entity-validation";
import userModel from "@/models/user.model";

@Component({
  components: {
    AusterButton,
    AusterInput,
    AusterSelect,
    ChangePasswordModal,
  },
})
export default class MyAccount extends Vue {
  genderOptions: any[] = [{ name: 'Masculino', value: 'M' }, { name: 'Feminino', value: 'F' }, { name: 'Outro', value: 'O' }];

  userInfo = {
    name: "",
    phoneNumber: "",
    cpf: "",
    birthDate: "",
    gender: "",
    email: "",
  };

  fieldErrors: any = {};

  async mounted() {
    this.loadUserInfo()
      .catch(error => console.error(error));
  }

  async loadUserInfo() {
    const loading = this.$vs.loading();
    try {
      this.userInfo = await userModel.getProfile();
      this.userInfo.gender = this.userInfo.gender?.charAt(0) || ""; // necessary to due vs-select bug
      this.userInfo.birthDate = this.userInfo.birthDate ? format(parseISO(this.userInfo.birthDate), "dd/MM/yyyy") : "";
    } catch (error) {
      console.error(error);
    }
    loading.close();
  }

  async updateUserInfo() {
    this.validateFields();
    if (Object.values(this.fieldErrors).filter(error => !!error).length) {
      this.$vs.notification({ text: "Verifique os erros indicados", color:"#FF6767" });
      console.error(this.fieldErrors);
      return;
    }
    const loading = this.$vs.loading();
    try {
      const userParam = { ...this.userInfo };
      delete userParam['company'];
      delete userParam['register'];
      delete userParam['userType'];
      await userModel.updateProfile({
        ...userParam,
        birthDate: parse(userParam.birthDate, "dd/MM/yyyy", new Date()),
        gender: this.translateGender(userParam.gender),
        id: undefined,
        role: undefined,
        status: undefined,
        crp: undefined,
        pixKey: undefined,
      });
      this.$vs.notification({ text: "Seus dados foram atualizados com sucesso", color: "#2E7179" });
    } catch (error) {
      this.$vs.notification({ text: "CPF ou email já cadastrados.", color:"#FF6767" });
      console.error(error);
    }
    loading.close();
    await this.loadUserInfo();
  }

  validateFields() {
    const validationDefinition: any = [
      { key: "name", validation: { lengthMax: 150 }},
      { key: "phoneNumber", validation: { kind: 'phone' } },
      { key: "cpf", validation: { kind: 'cpf' } },
      { key: "birthDate", validation: { kind: 'brDate' } },
      { key: "gender", validation: {} },
      { key: "email", validation: { kind: 'email' } },
    ];

    this.fieldErrors = validateEntity(this.userInfo, validationDefinition, ["name", "phoneNumber", "cpf", "birthDate", "gender", "email"]);
  }

  @Watch('userInfo', { deep: true })
  userInfoChanged() {
    // if a previous error was found, update validation when data changes
    if (Object.keys(this.fieldErrors).length) {
      this.validateFields();
    }
  }

  translateGender(value: string) { // necessary to due vs-select bug
    switch (value) {
      case "M":
        return "MALE";
      case "F":
        return "FEMALE";
      default:
        return "OTHER";
    }
  }
}
